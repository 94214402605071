import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  NgControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";
import { ViewContainerRefDirective } from "@modules/common/directives";
import { DynamicFormComponent } from "@modules/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { LabelStatus } from "../../services";

export const VALIDATION_MESSAGES = new InjectionToken<string>(
  "ValidationMessages"
);

@Component({
  selector: "abi-form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true,
    },
  ],
})
export class FormFieldComponent
  implements OnInit, AfterContentInit, OnChanges, AfterViewChecked
{
  @Input() labelColClass = "col-md-4";
  @Input() inputColClass = "col-sm";
  @Input() @HostBinding("class") cols = "col-md-6";

  get labelColClass2() {
    return (
      "pr-0 " +
      (this.horizontal ? this.labelColClass : "") +
      (this.wrap ? "" : " text-truncate")
    );
  }

  @Input() horizontal = true;
  @Input() label: string;
  @Input() formFieldClass: string;
  @Input() ignoreStatus: LabelStatus = LabelStatus.NotZero;
  @Input() extraLabel: string;
  @Input() errors: string[];
  @Input() wrap = false;
  @Input() validationMessages: any;
  @Input() path: string;
  @Input() tooltip: string | TemplateRef<any> = null;
  @Input() noFormGroup = false;

  // CUSTOM COMPONENT
  @Input() customComponent; // a direct component reference
  @Input() customData; // any data to pass directly into the custom component

  realLabel: string;

  @ContentChild(NgControl) content: NgControl;
  @ContentChild(NgControl, { read: ElementRef }) contentRef: ElementRef;
  control: AbstractControl;
  controlValues$: Observable<any>;
  private controlMessages: any;
  public forId: string;

  @ViewChild(ViewContainerRefDirective, { static: false })
  dynamicComponentTemplateOutlet!: ViewContainerRefDirective;
  componentRef: ComponentRef<any>;

  visible = true;

  @HostBinding("style.display")
  get style() {
    return this.visible ? "block" : "none";
  }

  constructor(
    private translate: TranslateService,
    @Optional() @Inject(VALIDATION_MESSAGES) validationMessages: any
  ) {
    this.validationMessages = this.validationMessages || validationMessages;
  }

  ngAfterViewChecked() {
    if (this.customComponent && this.dynamicComponentTemplateOutlet && !this.componentRef) {
      this.loadComponent();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.customData && this.componentRef) {
      // manually force the component to run it's 'change detection'
      (this.componentRef.instance as DynamicFormComponent).triggerChange(
        simpleChanges.customData.currentValue
      );
      // TODO: does not currently want to update the standard change detection (something undocumented with the inputs?)
      // (this.componentRef as ComponentRef<any>).instance.inputData = simpleChanges.customData.currentValue;
      // (this.componentRef.changeDetectorRef as ChangeDetectorRef).detectChanges();
    }
    if(simpleChanges.label){
      this.processLabel();
    }
  }

  ngOnInit(): void {
    this.processLabel();
  }

  processLabel(){
    if (this.label) {
      this.translate
      .get(this.label)
      .subscribe((lbl: string) => {
        this.setLabel(lbl, !!lbl);
      });
    }
  }

  setLabel(labelText: string, isVisible: boolean) {
    this.realLabel = labelText;
    this.visible = isVisible;
  }

  /**
   * Loads the specified component (that implements the DynamicFormComponent interface)
   * and binds the components change emitter to the native inputs value
   */
  loadComponent() {
    this.dynamicComponentTemplateOutlet.viewContainerRef.clear();
    this.componentRef = this.dynamicComponentTemplateOutlet.viewContainerRef.createComponent(
        this.customComponent
    );

    // we need to bind the 'valueChanged' event emitter values to the existing control's value
    this.componentRef.instance.valueChanged.subscribe((value) => {
      this.control.setValue(value);
    });
  }

  ngAfterContentInit(): void {
    if (!this.content) return; // simply no contents

    const nat = this.contentRef.nativeElement;
    nat.id = nat.id || this.content.name;
    this.forId = nat.id;
    if (!this.content.control) return; // missing valid form field reference

    this.control = this.content.control;
    this.controlValues$ = this.control.valueChanges;
    this.controlMessages = this.validationMessages?.[this.content.name] ?? {};

    if (this.usesParent()) { // Uses Parent Errors
      const parentPath = this.content.path[this.content.path.length - 2];
      this.controlValues$ = this.control.parent.valueChanges;
      this.controlMessages = { ...this.controlMessages, ...this.validationMessages?.[parentPath] ?? {} };
    }

    this.controlValues$.subscribe((s) => this.parseErrors());

    // Show Validation Error messages immediately
    // if (this.control.value){
    //   this.parseErrors();
    // }
  }

  parseErrors(): void {
    const mergedErrors = this.usesParent() ? { ...this.control.parent.errors, ...this.control.errors } : this.control.errors;
    this.updateErrorMessages(
      this.shouldShowErrors() ?
      mergedErrors : null
    );
  }

  usesParent(): boolean {
    return this.path === '..';
  }

  /**
   * Controls when the error messages should be shown
   * @returns true if the control is dirty or touched
   */
  shouldShowErrors(): boolean {
    const control = this.usesParent() ? this.control.parent : this.control;
    return control.dirty || control.touched;
  }

  private updateErrorMessages(errors: ValidationErrors): void {
    if (!errors) this.errors = null;
    else if (this.controlMessages) {
      this.errors = Object.keys(errors).map(
        (s) => this.controlMessages[s] ?? ""
      );
    }
  }
}
