/* eslint-disable @angular-eslint/no-input-rename */
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PipeTransform,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from "@angular/core";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { ProductSettingService } from "@modules/common/services/product-setting.service";
import { TranslateService } from "@ngx-translate/core";
import { LookupList } from "@shared/models";
import { forkJoin, merge, Observable, of, Subject, Subscription } from "rxjs";
import { auditTime, debounceTime, filter, map, switchMap, take, tap } from "rxjs/operators";
import { WebLayoutService } from "@services";
import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { AppInjector } from "@app/app.module";
import { Disposable, dotPathValueGetter } from "../..";

export type ColsData = { [id: string]: TableColumn };

export class ColumnData {
  public row: any;
  public column: TableColumn;
  public value: any;
  public index: number;
}

export type PipesCollection = Array<[PipeTransform, string | string[]] | PipeTransform>;

/**
 * Pipeline Pipe
 * Will Process/Reduce a set of 'Table/Column' compatible 'pipes'
 */
export class Pipeline implements PipeTransform {
  // private breakpoint: BreakpointObserver;
  constructor(private pipes: PipesCollection, private breakpoint?: BreakpointObserver) {
    // this.breakpoint = AppInjector.get(BreakpointObserver);
  }
  transform(value: any, ...args: any[]) {
    return this.pipes.reduce((acc, pipe) => {
      let fmt = "";
      if (pipe instanceof Array) {
        if (pipe[1] instanceof Array) {
          fmt = this.breakpoint?.isMatched(Breakpoints.Handset)
            ? pipe[1][1]
            : pipe[1][0];
        } else {
          fmt = pipe[1];
        }
        pipe = pipe[0];
      } else {
        pipe = pipe;
      }
      return pipe.transform(acc, fmt);
    }, value);
  }
}

@Directive({ selector: "ng-template[th]" })
export class HeadingDirective {
  @Input("th") public columns: string[] | string;
  constructor(public templateRef: TemplateRef<TableColumn>) {}

  public asArray(): string[] {
    if (typeof this.columns === "string") {
      return [this.columns];
    } else if (this.columns) {
      return this.columns;
    }
    return [];
  }
}
@Directive({ selector: "ng-template[tf]" })
export class FooterDirective implements OnChanges {
  @Input("tf") public columns: string[] | string;
  @Output() columnsChanged: EventEmitter<
    string[] | string
  > = new EventEmitter();

  constructor(public templateRef: TemplateRef<TableColumn>) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.columnsChanged.emit(this.columns);
  }

  public asArray(): string[] {
    if (typeof this.columns === "string") {
      return [this.columns];
    } else if (this.columns) {
      return this.columns;
    }
    return [];
  }
}

@Directive({ selector: "ng-template[td]" })
export class ColumnDirective {
  @Input("td") public columns: string[] | string;
  @Input() style = () => "";

  constructor(public templateRef: TemplateRef<ColumnData>) {}

  public matches(field: string): boolean {
    if (typeof this.columns === "string") {
      return this.columns === field;
    } else if (this.columns) {
      return this.columns.includes(field);
    }
    return false;
  }

  public asArray(): string[] {
    if (typeof this.columns === "string") {
      return [this.columns];
    } else if (this.columns) {
      return this.columns;
    }
    return [];
  }
}

export enum SortOrder {
  None,
  Ascending,
  Descending
}

export type FilterDelegate = (
  row: any,
  value: any,
  col: TableColumn
) => boolean;

export type StyleDelegate = (row: any, index?: number) => string;
export type ValueDelegate = (row: any) => string | number | Date | null;
export type FilterValue = string | number | null;

interface BasicDto {
  [key: string]: any;

}
export interface TypedTableColumn<T, KType extends string> extends TableColumn {
  field: KType | 'actions';
}

export interface TableColumn {
  field?: string;
  columnId?: string;
  header?: string;
  group?: string;
  /**
   * @deprecated use header instead
   */
  productHeader?: string;
  hideHandset?: boolean;
  sortOrder?: SortOrder;
  class?: string | StyleDelegate;
  tdClass?: string | StyleDelegate;
  thClass?: string | StyleDelegate;
  pipe?: [PipeTransform, string | string[], ...any] | PipeTransform;
  filter?: FilterValue;
  customFilter?: FilterDelegate;// for local filters - build your own comparator
  defaultSort?: SortOrder;
  skipFilter?: boolean;
  converter?: ValueDelegate;
  hidden?: boolean;
  showInFooter?: boolean;
  noTruncate?: boolean;
  mobile?: boolean;
  lookupLong?: string;
  lookupShort?: string;
  lookupFunc?: ValueDelegate;
  realHeader?: string;
  wrapHeader?: boolean;
  // queryParam: optionally defines the name of the query parameter to use in the resultant api query (only when filtering - not sorting)
  queryParam?: string;
  // static: will display the column - always, regardless of Header truthyness (useful for 'action' columns)
  // and hides it from the Selector dropdown
  static?: boolean;
  dto?: {// Used for Report Columns with metadata
    active?: boolean,
    code?: string,
    descritpion?: string,
    format?: string,
    modes?: string,
    order?: number,
    type?: "string" | "decimal" | "datetime" | "int"
  },
  canToggle?: boolean;
}

export type RowClickHandler = {
  index: number;
  row: any;
  event: any;
};

export type CellClickHandler = {
  index: number;
  row: any;
  column: TableColumn;
  event: any;
};

@Component({
  selector: "table[abi-table]",
  templateUrl: "./ability-table.component.html",
  styleUrls: ["./ability-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rowAnimation', [
      transition('void => *', [
        animate('0.3s ease-in-out', keyframes([
          style({display: 'block',height: 0, opacity: 0, offset: 0}),
          style({display: 'block',height: "*", opacity: 0, offset: 0.5}),// table-rows dont animate easily, use a mini-transition for 'display'
          style({display: 'table-row',height: "*", opacity: 0,  offset: 0.6}),
          style({display: 'table-row',height: "*", opacity: 1, offset: 1}),
        ]))
      ]),
      transition('* => void', [
        animate('0.6s ease-in-out', keyframes([
          style({display: 'table-row', height: "*", opacity: 1, offset: 0}),
          style({display: 'table-row', height: "*", opacity: 0, offset: 0.3}),// table-rows dont animate easily, use a mini-transition for 'display'
          style({display: 'block', height: "*", opacity: 0, offset: 0.4}),
          style({display: 'block', height: "0px", opacity: 0, offset: 1}),
        ]))
      ])
    ])
  ]
})
export class TableComponent extends Disposable
  implements AfterContentInit, OnChanges, OnDestroy, OnInit {
  @Input("abi-table") id: string;
  @Input() externalChangeTrigger: Subject<boolean> = new Subject();// pass in your observable that will triggen when the columns are done initing
  @Input() colsObs: Subject<TableColumn[]>;// pass in your observable that will triggen when the columns are done initing
  @Input() columns: TableColumn[] = [];
  @Input() layoutColumns: { [id: string]: TableColumn } = null;
  @Input() nestedColumns: TableColumn[];
  @Input() nestedColumnMap: Map<string, TableColumn[]> = null;
  @Input() currentColumnMapLevel: number = 0;
  @Input() rows: any[];// Code smell - implicitly loading state if rows is falsy
  @Input() errors: string[];
  @Input() theadClass = "thead-light";
  @Input() tfootClass = "thead-light";
  @Input() defaultSort = "";
  @Input() class = "";
  @Input() nestedRows = "";// the data field with the nested rows
  @Input() canFilter = false;
  @Input() popupOpen = false;
  @Input() remoteData: (columns: TableColumn[]) => Promise<any[]>;
  @Input() alwaysSort = false;
  @Input() skipTranslate = false;
  @Input() customSort: (column: TableColumn) => boolean;// Local Sorting Logic (not for Remote data useage)
  @Input() visibleColumns$: Subject<TableColumn[]> = new Subject();
  @Output() sorted: EventEmitter<any[]> = new EventEmitter();
  @Output() colsInited: EventEmitter<TableColumn[]> = new EventEmitter();
  @Output() rowClickHandler: EventEmitter<RowClickHandler> = new EventEmitter();
  @Output() cellClickHandler: EventEmitter<CellClickHandler> = new EventEmitter();
  @Input() hiddenColumns = false;
  @Input() onlyColumns = false;
  @Input() columnMap: { [field: string]: ColumnDirective } = null;
  @Input() headingMap: { [field: string]: HeadingDirective } = null;
  @Input() footerMap: { [field: string]: FooterDirective } = null;
  @Input() message: string = "";
  @Input() trackByFunction: (index: number, input: any) => any = undefined;
  @Input() loading = false;
  @Input() rowId: (row: any, index: number) => string = (row, index) => "row-" + index;
  // @Input() canClickRow: (row: any) => boolean = () => false;
  // @Input() canClickCell: (row: any, column: TableColumn) => boolean = () => false;
  @ContentChildren(HeadingDirective) headingTemplates: QueryList<HeadingDirective>;
  @ContentChildren(ColumnDirective) columnTemplates: QueryList<ColumnDirective>;
  @ContentChildren(FooterDirective) footerTemplates: QueryList<FooterDirective>;
  @ViewChildren(TableComponent) nestedTables: QueryList<TableComponent>;
  @ViewChildren("bodyRow") bodyRows: QueryList<ElementRef>;

  @HostBinding("class") get tableClass(): string {
    return "table " + this.class + (this.loading ? " loading" : "");
  }

  // 'Local' pagination requires page-aware tables
  @Input() page = 1;
  @Input() pageSize = 10;
  @Input() pageLocally = false;
  /**
   * Emits the filtered rows (after filtering but BEFORE pagination)
   */
  @Output() filteredColumns = new EventEmitter<TableColumn[]>();
  @Output() filteredRows = new EventEmitter<any>();

  state = 'normal';

  public displayNesting(row: any, column?: TableColumn): boolean {
    // return column.field === this.nestedRows && row[this.nestedRows]?.length;
    return row.expanded === true;
  }

  /**
   * @deprecated can cause ender/timing issues and warnings @see Function: $colIsVisible
   */
  public colIsVisible(fieldName: string) {
    return this.visibleColumns.some(s => s.field === fieldName);
  }

  /**
   * NOTE: can cause performace issues if used in a loop
   * Alternatively: use the visibleColumns$ observable to get the latest column visibility in your parent component
   */
  public $colIsVisible(fieldName: string) {
    return this.visCols.pipe(filter(cols => cols.some(s => s.field === fieldName)), map(cols => !!cols.length));
  }
  visCols: Observable<TableColumn[]>;// = of([]);

  get effectiveNestedColumns() {
    return this.nestedColumns || this.visibleColumns;
  }

  getKey(columnMap: Map<string, TableColumn[]>, level: number) {
    return columnMap?.size > 0 && Array.from(columnMap.keys())[level] || null;
  }

  responsive: Observable<boolean>;
  visibleColumns: TableColumn[] = [];
  extraChangeObs: Subject<boolean> = new Subject();
  // columnMap: { [field: string]: ColumnDirective };
  // headingMap: { [field: string]: HeadingDirective };
  // footerMap: { [field: string]: FooterDirective };
  filterer = new Subject<TableColumn[]>();
  sortOrderClasses: { [order: number]: string } = {
    0: "sort-none",
    1: "sort-asc",
    2: "sort-desc"
  };

  private translations: Subscription;
  private lookups: { [key: string]: LookupList } = {};
  private _rows: any[];

  get showFooter() {
    return this.columns && this.columns.some(s => s.showInFooter);
  }

  /**
   * Applies the Pipe prop from the column def.
   * note that this will be exported as-is.
   * If a number value is required in export, ensure the pipe dosnt convert to string or move the pipe to the Template
   */
  static toText(
    value: any,
    column: TableColumn,
    breakpoint: BreakpointObserver,
    useBreakpoints = true
  ): any {
    const result = value;
    const args = [];
    if (column.pipe) {
      if(column.pipe instanceof Pipeline){
        return column.pipe.transform(result);
      }

      let pipe: PipeTransform;
      if (column.pipe instanceof Array) {
        if (column.pipe[1] instanceof Array) {
          // @TODO: breakpoint elements should be deprecated
          args.push(breakpoint.isMatched(Breakpoints.Handset)
            ? column.pipe[1][1]
            : column.pipe[1][0]);
        } else {
          args.push(column.pipe[1]);
        }
        column.pipe.slice(2, column.pipe.length).forEach(p => args.push(p));// NOTE: added future support for array of args...
        pipe = column.pipe[0];
      } else {
        pipe = column.pipe;
      }
      return pipe.transform(result, ...args);
    }
    return result;
  }

  @Input() trClass: StyleDelegate = (row: any, index: number) => "";
  @Input() tfClass: StyleDelegate = () => "";

  /**
   * Generates Array or Arrays for the Table Export
   * @param skipHeader include or exclude headers in the export
   */
  public getTableData(skipHeader = false, useCellContents = false): any[][] {
    const headers = this.visibleColumns.map(c => c.realHeader);
    const results = this.rows.reduce((arr, row, i) => {
      let rowData = [];
      if(useCellContents) {
        // Get Raw Text from the Cell
        // Useful for more Complexely Rendered Table that may not have the data in the row arrays
        const rowEl = this.bodyRows.find((r, index) => index === i);
        rowData = this.visibleColumns.map((col, index) => {
          return (rowEl.nativeElement.children[index] as HTMLTableCellElement).innerText;
        });
      } else {
        // Using Table-ValueGetter to get the value from the row
        // This usually enought fpor a good export and it supports Date/Number/Strings formatting (maybe)
        rowData = this.visibleColumns.map((col) => this.getValue(row, col));
      }
      arr.push(rowData);
      // We use the same logic in the Template file to display the nested rows
      if(this.nestedColumnMap && this.getKey(this.nestedColumnMap, this.currentColumnMapLevel) && row[this.getKey(this.nestedColumnMap, this.currentColumnMapLevel)] && this.displayNesting(row)) {
        // we need to identify when/which Table is accessed
        this.nestedTables
        .filter(t => t.getTableElement().classList.contains('table-nested-row-' + i))
        .map(t => t.getTableData(true, useCellContents))
        .forEach(nestedTableDataRows => {
          nestedTableDataRows.forEach(nestedTableRow => arr.push(nestedTableRow));
        });
      }
      return arr;
    }, []);
    return skipHeader ? results : [headers, ...results];
  }

  public getTableElement(): HTMLTableElement {
    return this.elementRef.nativeElement as HTMLTableElement;
  }

  constructor(
    private _changeRef: ChangeDetectorRef,
    private breakpoint: BreakpointObserver,
    private productSetting: ProductSettingService,
    private lookupListService: LookupListService,
    private translate: TranslateService,
    private layoutService: WebLayoutService,
    private elementRef: ElementRef
  ) {
    super();
    this.filterer
      .pipe(debounceTime(500), this.notDisposed())
      .subscribe(this.doFilter.bind(this));
    this.responsive = breakpoint
      .observe(Breakpoints.Handset)
      .pipe(map(o => o.matches));

  }

  public triggerColumnSave(){
    this.extraChangeObs.next(this.breakpoint.isMatched(Breakpoints.Handset))
    this.columnsToJson();
  }

  tableInited: boolean = false;

  ngOnInit(): void {
    /**
     * A workaround to get the table to update itself for changes that it might not detect (leads to other issues like)
     */
    this.externalChangeTrigger.pipe(this.notDisposed(), auditTime(1000), take(20)).subscribe(() => {
      this._changeRef.detectChanges();
    });
    merge(this.extraChangeObs, this.responsive).pipe(
      map(mob =>
        this.columns.filter(
          c => (!c.hidden || !this.hiddenColumns || c.static) && (!c.mobile || !mob)
        )
      )
    ).subscribe(vCols => {
      if(this.tableInited){
        this.visibleColumns = vCols;
        this.visibleColumns$.next(vCols);
        this._changeRef.detectChanges();
      }
    });

    this.visCols = merge(this.extraChangeObs, this.responsive).pipe(
      map(mob =>
        this.columns.filter(
          c => (!c.hidden || !this.hiddenColumns || c.static) && (!c.mobile || !mob)
        )
      ),
      filter(cols => this.tableInited)
    );
  }

  ngAfterContentInit(): void {
    this.columnTemplates.changes
      .pipe(this.notDisposed())
      .subscribe(
        (templates) => (this.columnMap = this.populateColumnMap(templates))
      );
    this.headingTemplates.changes
      .pipe(this.notDisposed())
      .subscribe(
        (templates) => (this.headingMap = this.populateColumnMap(templates))
      );
    this.footerTemplates.changes
      .pipe(this.notDisposed())
      .subscribe(
        (templates) =>
          (this.footerMap = this.populateFooterColumnMap(templates))
      );
    this.columnMap = this.columnMap || this.populateColumnMap(this.columnTemplates);
    this.headingMap = this.headingMap || this.populateColumnMap(this.headingTemplates);
    this.footerMap = this.footerMap || this.populateFooterColumnMap(this.footerTemplates);

    // TODO: investigate if this inerferes
    if (!this.remoteData && this.rows?.length){
      this.filterLocal();
    }
    // else if (this.remoteData && !this.rows?.length){
    //   this.remoteData(this.columns); // trigger inital 'search' if available (TEST THIS)
    // }
    this.translations = this.translate.onLangChange.subscribe(evt => {
      this.translateColumns();
      this.initColumns();
    });

    // TODO: check if this is needed
    this._changeRef.detectChanges(); // this process happens after a debouce (somehow making angular ignore the change)

    // EXPERIMENTAL (NEED TESTS TO PROVE THIS WORKS)
    // INIT COLUMNS ONCE WE HAVE ALL TEMPLATES
    if(this.columns?.length)
      this.initColumns();
  }

  ngOnDestroy() {
    this.translations.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const cols = changes.columns;
    const lCols = changes.layoutColumns;
    if (
      (cols && cols.currentValue && cols.currentValue !== cols.previousValue) ||
      (cols &&
        lCols &&
        lCols.currentValue &&
        lCols.currentValue !== lCols.previousValue)
    ) {
      if(!cols.firstChange && cols.currentValue?.length)
        this.initColumns();
    } else if (this.skipTranslate)
      this.translateColumns();

    if (changes.rows) {
      if (this.alwaysSort && this.rows?.length) {
        // setTimeout(() => {// CODE SMELL: delay is always an indicator...
          const currentSort = this.columns.find(
            c => c.sortOrder !== SortOrder.None
          );
          if (currentSort) {
            this.processSorting(currentSort);
          }
        // }, 5);
      }
      // if (this.rows?.length)
      this._rows = this.rows?.length ? this.rows : []; // make a temporary copy of the rows before we start filtering them...
      if (!this.remoteData ){
        // this.localRows = [...this.rows];
        this.filterLocal();
      }
    }

    if(changes.page || changes.pageSize || changes.pageLocally){
      if (!this.remoteData){
        this.filterLocal();
      }
    }

    this._changeRef.detectChanges(); // this process happens after a debouce (somehow making angular ignore the change)
  }

  header(col: TableColumn, responsive = false): string {
    return !responsive || !col.hideHandset ? col.realHeader : "";
  }

  tdClass(column: TableColumn, row: any): string {
    return `${this.getClassValue(column.class, row) || ""} ${this.getClassValue(column.tdClass, row) || ""}`.trim();
  }

  /**
   * Value Getter - with the data field reference from the Column Definition, we get a value that usually needs some 'formatting'
   * Using the 'converter' function prop, we can transform the value (but it must return a string)
   * @param value usually a string, number, date or Object
   * @param column the TableColumn definition from which this value was derived
   * @returns string (but maybe anything)
   */
  public getValue(value: any, column: TableColumn): any {
    const path = column.field;
    if (!path) {
      return null;
    }
    else if (path === "self") {
      // self may also have it's converter
      if (column.converter) {
        return column.converter(value);
      }
      return value;
    }
    let cValue = null;
    if (column.converter) {
      cValue = column.converter(value);
    } else {
      cValue = dotPathValueGetter(value, path);
    }

    // LookupLong and LookupFunc go hand-in-hand
    if (column.lookupLong && this.lookups[column.lookupLong]) {
      if (column.lookupFunc) {
        return column.lookupFunc(this.lookups[column.lookupLong].item(cValue));
      }
      return (
        this.lookups[column.lookupLong].displayValue(cValue) || cValue || ""
      );

    // LookupShort just displays the 'value' from the lookup
    } else if (column.lookupShort && this.lookups[column.lookupShort]) {
      return this.lookups[column.lookupShort].display(cValue) || cValue || "";
    }
    return (cValue === null || cValue === undefined) ? "" : cValue;
  }

  /**
   * Gives you the final (transformed) output that may not be orderable/filterable locally
   * Used by the Export function for simplified data export (ideally: no pipes for some values)
   */
  finalValue(value: any, column: TableColumn | string): any {
    // CODE SMELL: Column type should not affect value type and handling
    if (typeof column === "string")
      return value[column];// probably deprecated

    // Applies: Converter, Value Refs, Lookups Refs
    const result = this.getValue(value, column);// GETS VALUES from Converter/Object/Lookups

    // Some Exported values should not be 'Pipe transformed'
    return this.runPipe(result, column);// RUN PIPES
  }

  runPipe(value: any, column: TableColumn, useBreakpoints = true): any {
    return TableComponent.toText(value, column, this.breakpoint, useBreakpoints);
  }

  columnStyle(column: TableColumn) {
    let result = `${column.class} ${column.thClass}`.trim();
    if (
      column.defaultSort === SortOrder.Ascending ||
      column.defaultSort === SortOrder.Descending
    ) {
      result += " " + this.sortOrderClasses[column.sortOrder];
    }
    return result;
  }

  footerStyle(column: TableColumn) {
    return `${column.class} ${column.thClass}`.trim();
  }

  // Trigger Sorting Process and Save Column config
  sortRows(column: TableColumn, event: any = null) {
    if (
      this.popupOpen ||
      (event &&
        event.target !== event.currentTarget &&
        event.target.parentNode !== event.currentTarget)
    ) {
      return;
    }
    if (
      [SortOrder.Ascending, SortOrder.Descending].includes(column.defaultSort)
    ) {
      const previousSortCol = this.columns.find(c => c.sortOrder !== SortOrder.None);
      this.sortList(column, previousSortCol);
      this.columnsToJson();
    }
  }

  getColumn(fieldName: string): TableColumn {
    return this.columns.find(c => c.field === fieldName);
  }

  getColumnTemplate(column: TableColumn): ColumnDirective {
    const field = column.columnId || column.field;
    if (this.columnMap && field in this.columnMap) {
      return this.columnMap[field];
    }
    return null;
  }

  getHeadingTemplate(column: TableColumn): HeadingDirective {
    const field = column.columnId || column.field;
    if (this.headingMap && field in this.headingMap) {
      return this.headingMap[field];
    }
    return null;
  }

  getFooterTemplate(column: TableColumn): FooterDirective {
    const field = column.columnId || column.field;
    if (this.footerMap && field in this.footerMap) {
      return this.footerMap[field];
    }
    return null;
  }

  stopClick($event: any) {
    $event.stopPropagation();
  }

  filter(column: TableColumn, value: string | number | null) {
    if (column && column.filter !== value && !( !column.filter && !value )) {
      column.filter = value;
      this.filterer.next(this.columns);
    }
  }

  private translateColumnsObservable() {
    return forkJoin(this.columns.map(c => c.header
      ? ( !this.skipTranslate || c.header.includes(".") ? this.translate.get(c.header) : of(c.header))
      : of(""))
    );
  }

  private translateColumns() {
    this.translateColumnsObservable()
    .subscribe((newHeaders: string[]) => {
      this.columns.forEach((c, i) => {
        c.realHeader = newHeaders[i];
      });
    });
  }

  // Removes saved TableColumn config from session store
  public resetFilters(){
    if (this.id)
    sessionStorage.removeItem(this.id);
  }

  translatedHeaders: string[] = [];
  /**
   * Given Columns and LayoutColumns:
   * update headers and apply saved/layout columns settings ontop of given columns array
   * 1. if layoutColumns are specified, use them first, then check for sessionStore data
   */
  private initColumns(andSave: boolean = false): void {
    let colsData: ColsData = null;
    if (this.id) {
      colsData =
        this.layoutColumns || this.layoutService.getSessionLayout(this.id);
    }

    // Assemble translated headers
    this.translateColumnsObservable()
    .pipe(
      tap((newHeaders: string[]) => {
        this.translatedHeaders = newHeaders;
      }),
      switchMap(() =>  {
        // make dictionary of lookups to load
        const lookups = this.columns.filter(c => c.lookupLong || c.lookupShort)
        .reduce(
          (acc, c) => (
            {
              ...acc,
              [c.lookupLong || c.lookupShort]: this.lookupListService.lookupList(c.lookupLong || c.lookupShort, 0)
            }
          ),
          {}
        );
        if(Object.keys(lookups).length)// empty forkjoin wont resolve, so we return dummy observable
          return forkJoin(lookups);
        return of({});// return dummy observable
      }),
      )
      .subscribe((lookupLists: Record<string, LookupList>) => {
        this.lookups = lookupLists;
        this.columns.forEach((c, i) => {
          c.filter = c.filter ?? null;
          c.realHeader = this.translatedHeaders[i];// this should ideally be passed in through the pipes
          if (c.productHeader) {
            const pHeader = this.productSetting.label(c.productHeader);// PRODUCT SETTING LABEL (this should probably be deprecated)
            c.realHeader = pHeader || c.realHeader;
          }
          c.hidden = c.hidden || !c.realHeader;// HIDE HEADER if HIDDEN or blank Translation
          c.wrapHeader = c.wrapHeader || false;
          c.noTruncate = c.noTruncate || false;
          if (!c.sortOrder) {
            c.sortOrder =
              (c.field === this.defaultSort ? c.defaultSort : SortOrder.None);
          }
          const lName = c.lookupLong || c.lookupShort || "";
          if (lName && !this.lookups[lName]) {
            throw new Error(`${lName}: LookupNotLoaded`);
          }
          if (this.id && colsData) {
            const colData = colsData[c.columnId || c.field];
            if (colData) {
              c.sortOrder = colData.sortOrder || SortOrder.None;
              c.hidden = colData.hasOwnProperty('hidden') ? colData.hidden : c.hidden;
              c.filter = colData.filter ?? null;
            } else {
              c.filter = null;
            }
          }
        });

        // Ensure 'init status' is set in the next cycle (not this one)
        setTimeout(() => {
          this.tableInited = true;// this will allow 'visible columns to be updated'
          this.triggerColumnSave();

          // TWO available ways to detect cols init
          this.colsInited.emit(this.columns);
          this.colsObs?.next(this.columns);
        }, 1);
        // if(andSave)
        //   this.columnsToJson();
        // this.extraChangeObs.next(this.breakpoint.isMatched(Breakpoints.Handset))
      });
  }

  private getClassValue(value: string | StyleDelegate, row: any) {
    return typeof value === "function" ? value(row) : value;
  }

  private populateColumnMap<T extends HeadingDirective | ColumnDirective>(
    list: QueryList<T>
  ): { [key: string]: T } {
    const mapp: { [key: string]: T } = {};
    list.forEach(t => t.asArray().forEach(f => (mapp[f] = t)));
    return mapp;
  }

  private populateFooterColumnMap<T extends FooterDirective>(
    list: QueryList<T>
  ): { [key: string]: T } {
    const mapp: { [key: string]: T } = {};
    list.forEach(t => {
      t.asArray().forEach(f => (mapp[f] = t));
      t.columnsChanged.subscribe(s => {
        this.footerMap = this.populateFooterColumnMap(this.footerTemplates);
      });
    });
    return mapp;
  }

  private sortList(
    column: TableColumn,
    currentSort: TableColumn,
    local = false
  ) {
    let a = SortOrder.Descending;
    let b = SortOrder.Ascending;
    if (column.defaultSort === SortOrder.Descending) {
      a = SortOrder.Ascending;
      b = SortOrder.Descending;
    }

    if (this.defaultSort) {
      if (currentSort === column) {
        if (column.sortOrder !== b) {
          currentSort.sortOrder = SortOrder.None;
          currentSort = this.columns.find(c => c.field === this.defaultSort);
          currentSort.sortOrder = b;
        } else {
          currentSort.sortOrder = a;
        }
      } else if (currentSort) {
        currentSort.sortOrder = SortOrder.None;
        currentSort = column;
        currentSort.sortOrder = b;
      }
    } else {
      if (currentSort === column) {
        if (column.sortOrder !== a) {
          currentSort.sortOrder = a;
        } else {
          currentSort.sortOrder = b;
        }
      } else {
        if (currentSort) {
          currentSort.sortOrder = SortOrder.None;
        }
        currentSort = column;
        currentSort.sortOrder = b;
      }
    }
    if (this.remoteData && !local) {
      // Sorting Params are sent to the Server request
      this.callRemoteData(rows => {});
    } else if (!(this.customSort && this.customSort(currentSort))) {
      this.processSorting(currentSort);// sort the actual data (locally)
    }
    return currentSort;
  }

  callRemoteData(cb: (rows: any[]) => void) {
    this.remoteData(this.columns)
    .then(rows => {
      cb(this.rows);
    })
    .catch(err => {
      console.error(err);
      // cb(this.rows);
    });
  }

  // Sorting Done locally
  private processSorting(currentSort: TableColumn){
    // console.log('processSorting', currentSort);
    this.rows.sort((a2, b2) =>
      this.order(
        currentSort,
        currentSort.sortOrder === SortOrder.Descending ? b2 : a2,
        currentSort.sortOrder === SortOrder.Descending ? a2 : b2
      )
    );
    this.sorted.emit(this.rows);
  }

  private order(
    column: TableColumn,
    aDto: any,
    bDto: any
  ): number {
    const a = this.getValue(aDto, column);
    const b = this.getValue(bDto, column);
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() - b.getTime();
    }
    if (Number.isFinite(a)) {
      return +a - +b;
    }
    // Note: when sorting locally and is not a string - might crash...
    return !!a && !!b ? (a as string).localeCompare(b) : !a && !!b ? -1 : !a && !b ? 0 : 1;
  }

  private doFilter(): void {
    this.columnsToJson();
    if (this.remoteData && this.columns) {
      this.callRemoteData(rows => {
        this._changeRef.detectChanges();
      });
    } else {
      this.filterLocal();
    }
    this.filteredColumns.emit(this.columns);
  }

  // localRows: any[] = [];
  private filterLocal() {
    // this.localRows = [...this.rows];
    if (!this._rows && this.rows?.length) {
      this._rows = [...this.rows];// sketchty data copying
    }
    let tempRows = [...this._rows];// cannot use any other copy method, as it will break the reference (dates will be lost)
    // this.rows = this._rows;
    const hasFilters = this.columns.some(col => col.filter?.toString()?.trim());
    if (hasFilters) {
      for (const col of this.columns) {
        const val = col.filter?.toString()?.toLowerCase();
        if (val) {
          if (col.customFilter) {
            // console.log('custom filter found', col)
            tempRows = tempRows.filter(r =>
              col.customFilter(
                r,
                ("" + this.getValue(r, col)).toLowerCase(),
                col
              )
            );
          } else {
            tempRows = tempRows.filter(r =>
              ("" + this.getValue(r, col)).toLowerCase().includes(val)
            );
          }
        }
      }
    }

    this.filteredRows.emit(tempRows);

    if(this.pageLocally)
      tempRows = tempRows.slice((this.page-1)*this.pageSize, this.page*this.pageSize);

    // this.visibleRows.emit(tempRows);
    this.rows = tempRows;
    // always update table when triggering filters, as they may have been removed
    this._changeRef.detectChanges(); // this process happens after a debouce (somehow making angular ignore the change)
  }

  // TableColumn array to Hashmap
  private arrayToHash(columns: TableColumn[]): ColsData {
    const result = Object.assign(
      {},
      ...columns /*.filter(col => col.hidden || col.sortOrder || col.filter)*/
        .map(column => ({
          [column.columnId || column.field]: {
            sortOrder: column.sortOrder,
            hidden: column.hidden,
            filter: column.filter
          }
        }))
    );
    return result;
  }

  /**
   * Save Column configuration to session storage
   */
  private columnsToJson() {
    if (this.id && !this.layoutColumns && this.columns) {
      this.layoutService.setSessionLayout(this.id, this.arrayToHash(this.columns));
    }
  }

  get hasRowClickObservers() {
    return !!this.rowClickHandler.observers.length;
  }

  rowClicked(index: number, row: any, event: any) {
    // console.log('rowClicked', row);
    // if(this.canClickRow(row)) {
      // event.stopPropagation();
      this.rowClickHandler.emit({ index, row, event });
    // }
  }

  cellClicked(index: number, row: any, column: TableColumn, event: any) {
    // console.log('cellClicked', row, column);
    // if(this.canClickCell(row, column)) {
      // event.stopPropagation();
      this.cellClickHandler.emit({ index, row, column, event });
    // }
  }

  // TODO: this actually needs to be re-thought and specifically implemented for certain cases
  // external convenience function to trigger a redraw (if the row data was changed externally)
  public triggerRedraw(): void {
    this._changeRef.detectChanges();
    // this._changeRef.detach();
    // this._changeRef.markForCheck();
  }
}
