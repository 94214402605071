<abi-spinner *ngIf="loading"></abi-spinner>
<div *ngIf="(tenant$ | async) === ''; else routes">
    <div class="row home-companies" data-cy="home-companies">
        <div
            class="col-6 col-sm-4 col-md-3 col-lg-2"
            *ngFor="let company of companies"
            >
            <ng-container
                *ngTemplateOutlet="companyLink; context: { company: company }"
            ></ng-container>
        </div>
    </div>
    <div class="mt-3" *ngIf="testCompanies.length">
        <mat-divider></mat-divider>
        <h4>Test Companies</h4>
        <div class="row home-test-companies" data-cy="home-test-companies">
            <div
                class="col-6 col-sm-4 col-md-3 col-lg-2"
                *ngFor="let company of testCompanies"
            >
                <ng-container
                    *ngTemplateOutlet="companyLink; context: { company: company }"
                ></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #routes>
    <h4 class="text-primary text-center" *ngIf="childName | async as link">
        <i [ngClass]="['', link.image, 'fa-fw']"></i>&nbsp;
        {{ link.name }}
    </h4>
    <div *ngIf="(childRoutes | async) as childRoutesS; else mainRoutesS" class="row child-routes" data-cy="child-routes">
        <ng-template ngFor let-link [ngForOf]="childRoutesS">
            <div class="col-5 col-sm-3">
                <div class="btn-group my-2 btn-block">
                    <a role="button" class="btn btn-outline-primary text-wrap" [routerLink]="link.link" [class.disabled]="!link.link.length">
                        <i [ngClass]="['', link.image, 'fa-fw']"></i>&nbsp;
                        <span *ngIf="link.labelSetting; else txtOnly" [abiProductLabel]="link.labelSetting" [label]="link.name" [extra]="link.labelSetting2"></span>
                        <ng-template #txtOnly>
                            {{ link.name }}
                        </ng-template>
                    </a>
                </div>
            </div>
            <div class="col-sm-9 col-7 list-group">
                <mat-nav-list class="row">
                <mat-list-item *ngFor="let layout of linkLayouts(link)" style="height: 40px;">
                    <a class="col-md-6 col-lg-4 px-0" [routerLink]="layoutLink(link.link, layout.layoutId)">
                    <i [class]="'mr-1 text-primary ' + layout.icon"></i>
                        {{ layout.caption }} </a>
                    <small  class="d-none d-md-block col-md-6 col-lg-8 text-muted">{{ layout.description }}</small>
                </mat-list-item>
                </mat-nav-list>
            </div>
            <div class="w-100"></div>
        </ng-template>
    </div>

    <!-- MAIN ROUTES -->
    <ng-template #mainRoutesS>
        <div class="row home-main-routes mx-n2 mx-lg-n3" data-cy="home-main-routes">
            <div class="col-6 col-md-3 px-2 px-lg-3" *ngFor="let link of mainRoutes | async">
                    <ng-container
                        *ngTemplateOutlet="
                        menuLink;
                        context: { link: link, font: 'big-font' }
                        "
                    ></ng-container>
            </div>
        </div>

        <hr class="my-2"/>

        <!-- METRICS -->
        <div class="row justify-content-center home-metrics mx-n2 mx-lg-n3">
            <div class="col-6 col-md-3 px-2 px-lg-3" *ngFor="let link of metrics | async">
                <div class="my-2 w-99 text-truncate">
                    <a
                        role="button"
                        class="btn btn-outline-primary d-block"
                        [routerLink]="['reporting', 'stats', link.id, username | async]"
                        >
                        <div>
                            <span class="big-font position-relative"
                            >{{ link.value !== -99999 ? link.value : '-' }}
                            <i class="fa-fontt" [ngClass]="arrowStyle(link)"></i>
                            </span>
                        </div>
                        <span class="">{{ link.name | translate }}</span>
                    </a>
                </div>
            </div>
        </div>


        <hr class="my-2"/>

        <!-- SUB ROUTES -->
        <div class="row home-sub-routes mx-n2 mx-lg-n3" data-cy="home-sub-routes">
            <div class="col-4 col-md-2 px-2 px-lg-3" *ngFor="let link of subRoutes | async" >
                <ng-container *ngIf="link.layoutName === 'FollowupList';else genericLink">
                    <div [matBadgeHidden]="!followupCount" [matBadge]="followupCount" matBadgePosition="after" matBadgeColor="warn">
                        <ng-container
                            *ngTemplateOutlet="
                            menuLink;
                            context: { link: link, font: 'medium-font' }
                            "
                        ></ng-container>
                    </div>
                </ng-container>
                <ng-template #genericLink>
                    <div>
                        <ng-container
                            *ngTemplateOutlet="
                            menuLink;
                            context: { link: link, font: 'medium-font' }
                            "
                        ></ng-container>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="haveTips | async" class="col-4 col-md-2 px-2 px-lg-3">
                <div>
                    <div class="my-2 w-99 text-truncate">
                        <button
                            class="btn btn-outline-primary btn-block"
                            (click)="viewTip()"
                            >
                            <i class="far fa-comments medium-font"></i>
                            <div class="d-block text-truncate">
                                <span class="">{{ 'System.Tips' | translate }}</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #menuLink let-link="link" let-font="font">
    <div class="my-2 w-99 text-truncate">
        <a role="button" class="btn btn-outline-primary d-block" [routerLink]="link.link" [class.disabled]="!link.link.length">
            <ng-container
                *ngTemplateOutlet="
                linkContent;
                context: { link: link, font: font}
                "
            ></ng-container>
        </a>
    </div>
</ng-template>

<ng-template #linkContent let-link="link" let-font="font">
    <i [ngClass]="[font, link.image]"></i>
    <span
        *ngIf="link.labelSetting; else txtOnly"
        class="d-block text-truncate"
        [abiProductLabel]="link.labelSetting"
        [label]="link.name"
        [extra]="link.labelSetting2">
    </span>
    <ng-template #txtOnly>
        <div class="d-block text-truncate">
            <span class="">{{ link.name }}</span>
        </div>
    </ng-template>
</ng-template>

<ng-template #companyLink let-company="company">
    <a role="button" class="btn btn-outline-primary btn-block my-2" [routerLink]="['/' + company.id]" [class.disabled]="!company.id" [title]="company.name">
        <div class="img-container">
            <img [src]="'assets/logos-colour/' + company.icon + '.svg?v=1'" class="logo"/>
        </div>
        <div class="name-container text-truncate">
            {{ company.name }}
        </div>
    </a>
</ng-template>
